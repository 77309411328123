@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Montserrat:wght@500&family=Pinyon+Script&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;

* {
    padding: 0px;
    margin: 0px;
    user-select: none;
    scroll-behavior: smooth;
}

#contenu {
    margin-top: - calc(50vh - 50px);
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.slide-down {
    animation: slideDown 0.5s forwards;
}


#tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* Placez les particules derrière le contenu */
}


@tailwind components;
@tailwind utilities;
